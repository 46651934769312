@font-face {
  font-family: "Hasklig";
  src: local("Hasklig-Regular"), url("./fonts/Hasklig-Regular.ttf") format("truetype");
}

.hljs {
  font-size: 0.9em;
  font-family: "Hasklig" source-code-pro, Menlo, Monaco, Consolas monospace;
}

.MathJax {
  font-family: sans-serif !important;
  font-weight: 300 !important;
}

body {
  margin: 0;
  color: #4D4D4D;
  font-family: "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}